import React from "react"
import { graphql } from "gatsby"

import { Helmet } from "react-helmet"
//import Img from "gatsby-image/withIEPolyfill"
import NewsItem from "../components/newsitem";

export default ({ data }) => {
  //console.log(data)
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Titles</title>
        <link rel="canonical" href= {process.env.SITE_URL + "/news"} />
      </Helmet>

      <h1 className="page_title">News</h1>

      <div className="main_content">
        {data.allWordpressPost.edges.map(({ node }) => (
         <NewsItem  node={node} list="list"></NewsItem>

        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allWordpressPost (sort: {fields:date, order: DESC}) {
      edges {
        node {
          id
          title
          content
          slug
          date(formatString: "MMMM DD, YYYY")
          acf {
            news_image {
              id
              source_url
              media_details {
                width
                height
                file
                sizes {
                  tiny {
                    file
                    width
                    height
                    mime_type
                    source_url
                  }    
                  thumbnail {
                    file
                    width
                    height
                    mime_type
                    source_url
                  }    
                  medium {
                    width
                    height
                    source_url
                  }
                  large {
                    width
                    height
                    source_url
                  }
                  full {
                    width
                    height
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
